const sampleWorks = [
    {
        id: 1,
        image: 'https://cdn.dribbble.com/userupload/12484791/file/original-e5c40976c87eaee1fae84669bf218f93.png?resize=2048x1455&vertical=center',
        headline: 'PS5 Landing Ui',
        description: '',
        link: 'https://dribbble.com/shots/23445674-Playstation-landing-Ui',
    },
    {
        id: 2,
        image: 'https://cdn.dribbble.com/userupload/12110867/file/original-9b7b75bd53bc28889e97833a469b05c1.png?resize=2048x10159&vertical=center',
        headline: 'E-Commerce Store',
        description: 'Freight Haulers is a platform designed to connect dispatchers with drivers within a specific area throughout the entire process of transporting a load from point A to B',
        link: 'https://dribbble.com/shots/23312318-Freight-haulers-website-design',
    },
    {
        id: 3,
        image: 'https://cdn.dribbble.com/userupload/12484793/file/original-294eeeaa098d6858d459241efedbe506.png?resize=2048x1455&vertical=center',
        headline: 'Playstation 4 landing Ui',
        description: '',
        link: 'https://dribbble.com/shots/23445674-Playstation-landing-Ui',
    },
    {
        id: 4,
        image: 'https://cdn.dribbble.com/userupload/12122209/file/original-cdfd70cfce0bce3c21f272712f6fed78.png?resize=1504x1129&vertical=center',
        headline: 'Freight haulers web app case study',
        description: 'Freight Haulers is a platform designed to connect dispatchers with drivers within a specific area throughout the entire process of transporting a load from point A to B',
        link: 'https://dribbble.com/shots/23316270-Freight-haulers-web-app-case-study',
    },
    {
        id: 5,
        image: 'https://cdn.dribbble.com/userupload/12085276/file/original-6739c8a10a4e3f61cfa75392b2c64766.png?resize=1504x1129&vertical=center',
        headline: 'Freight logistics app case study',
        description: 'Empireload serves as an advanced logistics platform, providing drivers with instantaneous access to freight opportunities featuring premium and exclusive pricing.',
        link: 'https://dribbble.com/shots/23303250-Freight-logistics-app-case-study',
    },
    {
        id: 6,
        image: 'https://cdn.dribbble.com/userupload/12427363/file/original-e4ae405080270ae9b2b4423390614c28.jpg?resize=2048x6239&vertical=center',
        headline: 'GTA Vi - Landing UI',
        description: '',
        link: 'https://dribbble.com/shots/23425201-GTA-Vi-Landing-UI',
    },
    {
        id: 7,
        image: 'https://cdn.dribbble.com/userupload/12484792/file/original-6a13cdbb1e726bdf914d2eef7343d35e.png?resize=2048x1455&vertical=center',
        headline: 'PS5 Portable landing Ui',
        description: '',
        link: 'https://dribbble.com/shots/23445674-Playstation-landing-Ui',
    },
    {
        id: 8,
        image: 'https://cdn.dribbble.com/userupload/12084848/file/original-7a963cdc0b7f5195d2827831aa44df3e.png?resize=1504x1129&vertical=center',
        headline: 'Trading platform UI design',
        description: '',
        link: 'https://dribbble.com/shots/23303202-Trading-platform-UI-design',
    },
    {
        id: 9,
        image: 'https://cdn.dribbble.com/userupload/12084829/file/original-ccd3438ffd872311f075f5dac228f2f1.png?resize=1504x1129&vertical=center',
        headline: 'Music streaming application',
        description: '',
        link: 'https://dribbble.com/shots/23303193-Music-streaming-application',
    },
    {
        id: 10,
        image: 'https://cdn.dribbble.com/userupload/12084766/file/original-d1282fb47a4ca695296b0db44ddc6e6c.png?resize=1504x1129&vertical=center',
        headline: 'Dribbble NFT concept',
        description: '',
        link: 'https://dribbble.com/shots/23303165-Dribbble-NFT-concept',
    },
    {
        id: 11,
        image: 'https://cdn.dribbble.com/userupload/12484794/file/original-7c23f5345a1b141e6edd21759982a87e.png?resize=2048x1455&vertical=center',
        headline: 'Pleystation Headset Ladning Ui',
        description: '',
        link: 'https://dribbble.com/shots/23445674-Playstation-landing-Ui',
    },
];

export default sampleWorks;