import React, { useContext } from 'react';
import { FaGoogle, FaStar } from 'react-icons/fa'; // Assuming you have react-icons installed
import { ThemeContext } from '../../ThemeContext';

const testimonials = [
    {
        name: "Ali Abyari",
        title: "Marketing Director, Arasp Agency",
        quote: "Working with this marketing team has been a game-changer for our business. They bring fresh perspectives and innovative strategies that have significantly boosted our brand awareness and customer engagement.",
    },
    {
        name: "Juan Wood",
        title: "VP of Marketing, Ring Solutions",
        quote: "Matt and his team helped me grow my business beyond my wildest dreams! I really appreciate how they’re always there to help, whether it’s 9 AM or 11 PM. When I needed assistance with marketing, my website, and content management, I found the best with Zimapeak. I highly recommend them!",
    },
    {
        name: "Zohreh Bakhshi",
        title: "Founder, Alpha Gym Fitness",
        quote: "Choosing zimapeak for marketing service was one of the best decisions I made for my business. Their tailored strategies and personalized approach have helped my gym attract more leads and convert them into loyal customers.",
    },
    {
        name: "Donald Alexander",
        title: "Founder & CEO, ONJ Solutions",
        quote: "Zimapeak transformed my business! They didn't just create a presentable website but crafted a highly functional one that they maintain regularly to ensure it's always operational. Their expertise in ad campaigns and web development has been invaluable. It's such a relief to find a team passionate about their work, giving me confidence that my business's assets are well looked after. Communication is top-notch, with extremely responsive support for any questions I have. Put your project in Matt's hands, and with his dedication and skillful execution, you can watch your business reach new heights. Highly recommend!",
    },
];

const Testimonial = () => {
    const { darkMode } = useContext(ThemeContext);

    return (
        <div className={` ${darkMode ? 'text-white' : 'text-dark'}`}>
            <div className="max-w-7xl mx-auto flex flex-col justify-center items-center ">
                <div className='w-full md:w-1/2'>
                    <h1 className="text-center text-2xl font-extrabold tracking-tight mb-8">What Our Clients Say</h1>
                    <p className="text-center text-lg mb-12">Hear from our satisfied clients who have experienced the transformative power of our marketing services.</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-8 md:p-0">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="flex flex-col rounded-lg border border-4 overflow-hidden">
                            <div className={`px-6 py-8 bg-white text-dark`}>
                                <div className="flex items-center mb-4">
                                    <FaGoogle className="text-primary text-4xl mr-2" />
                                    <div>
                                        <div className="font-semibold">{testimonial.name}</div>
                                        <div className="flex items-center">
                                            <FaStar className="text-secondary mr-1" />
                                            <FaStar className="text-secondary mr-1" />
                                            <FaStar className="text-secondary mr-1" />
                                            <FaStar className="text-secondary mr-1" />
                                            <FaStar className="text-secondary" />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-m text-left italic mb-4">{testimonial.title}</div>
                                <p className={`text-m text-left text-dark`}>{testimonial.quote}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Testimonial;
